import { api, apiCall } from "../api";
import { store } from "../store";
import { ApiResponse } from "../types";
import { setProfile } from "../features/profile";

type Response = {
  user: {
    id: number;
    uuid: string;
    name: string;
    email: string;
    profilePhoto: string | null;
    jobRole: string | number | null;
  },
  account: {
    id: number;
    uuid: string;
    email: string;
    fullName: string;
    companyName: string;
    logo: string | null;
    countryName: string | null;
    companyIndustry: string | number | null;
    webLink: string | null;
    activated: boolean;
  }
};

const profileAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<Response, void>({
      query: () => ({
        url: "/profile/me",
        method: "GET"
      }),
    }),
    createProfile: builder.query<any, any>({
      query: (body) => ({
        url: `/account-details/register`,
        method: "PUT",
        body
      })
    }),
    updateProfile: builder.query<any, any>({
      query: (body) => ({
        url: `/account-details`,
        method: "PUT",
        body
      })
    }),
    uploadAvatar: builder.query<any, any>({
      query: (body) => ({
        url: `/user-details/profile-photo`,
        method: "POST",
        body
      })
    }),
    uploadCompanyLogo: builder.query<any, any>({
      query: (body) => ({
        url: `/account-details/logo`,
        method: "POST",
        body
      })
    }),
    deleteProfile: builder.query<any, any>({
      query: (email) => ({
        url: `/account_block/accounts/:id?email=${email}`,
        method: "DELETE"
      })
    }),
    subscribeForUpdates: builder.query<any, any>({
      query: (body) => ({
        url: `/academy/subscription-list`,
        method: "PUT",
        body
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getProfile = async (forceFetch = false): Promise<any> => {
  const state = store.getState();

  // If there's cached data, return it instead of making a new request
  if (!forceFetch && state?.profile?.metadata) {
    return { data: state.profile.metadata };  // Return profile data
  } else {
    // If no cached data, proceed with the API call
    const { data, error } = await store.dispatch(
      profileAPI.endpoints.getProfile.initiate(null, { forceRefetch: true })
    );
    
    if (data && !error) {
      await store.dispatch(
        setProfile(data)
      );
    }

    return { data, error };
  }
};

export const updateProfile = async (body): Promise<any> => {
  return apiCall(profileAPI.endpoints.updateProfile, body);
};

export const createProfile = async (body): Promise<any> => {
  return apiCall(profileAPI.endpoints.createProfile, body);
};

export const uploadAvatar = async (body): Promise<any> => {
  return apiCall(profileAPI.endpoints.uploadAvatar, body);
};

export const uploadCompanyLogo = async (body): Promise<any> => {
  return apiCall(profileAPI.endpoints.uploadCompanyLogo, body);
};

export const deleteProfile = async (email): Promise<any> => {
  return apiCall(profileAPI.endpoints.deleteProfile, email);
};

export const subscribeForUpdates = async (body): Promise<any> => {
  return apiCall(profileAPI.endpoints.subscribeForUpdates, body);
};
