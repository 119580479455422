import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const copilotAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getChats: builder.query<any, any>({
      query: (tab) => ({
        url: `/v1/chatbot/${tab}`,
        method: "GET"
      }),
    }),
    getChatById: builder.query<any, any>({
      query: ({ id, tab }) => ({
        url: `/v1/chatbot/${tab}/${id}`,
        method: "GET"
      }),
    }),
    sendChatQuestion: builder.mutation<any, any>({
      query: ({ body, tab }) => ({
        url: `/v1/chatbot/${tab}`,
        method: "POST",
        body // { "question": "string", chatType: "string" }
      })
    }),
    sendChatQuestionById: builder.mutation<any, any>({
      query: ({ body, sessionId, tab }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}`,
        method: "PUT",
        body // { "question": "string", chatType: "string" }
      })
    }),
    deleteChat: builder.mutation<any, any>({
      query: ({ id, tab }) => ({
        url: `/v1/chatbot/${tab}/${id}`,
        method: "DELETE"
      })
    }),
    likeMessage: builder.mutation<any, any>({
      query: ({ sessionId, messageId, tab }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}/like/${messageId}`,
        method: "POST"
      })
    }),
    dislikeMessage: builder.mutation<any, any>({
      query: ({ sessionId, messageId, tab }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}/dislike/${messageId}`,
        method: "POST"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getChats = async (tab): Promise<any> => {
  return apiCall(copilotAPI.endpoints.getChats, tab);
};

export const getChatById = async ({ id, tab }): Promise<any> => {
  return apiCall(copilotAPI.endpoints.getChatById, { id, tab });
};

export const sendChatQuestion = async ({ body, tab }): Promise<any> => {
  return apiCall(copilotAPI.endpoints.sendChatQuestion, { body, tab });
};

export const sendChatQuestionById = async ({ body, sessionId, tab }): Promise<any> => {
  return apiCall(copilotAPI.endpoints.sendChatQuestionById, { body, sessionId, tab });
};

export const deleteChat = async ({ id, tab }): Promise<any> => {
  return apiCall(copilotAPI.endpoints.deleteChat, { id, tab });
};

export const likeMessage = async ({ sessionId, messageId, tab }): Promise<any> => {
  return apiCall(copilotAPI.endpoints.likeMessage, { sessionId, messageId, tab });
};

export const dislikeMessage = async ({ sessionId, messageId, tab }): Promise<any> => {
  return apiCall(copilotAPI.endpoints.dislikeMessage, { sessionId, messageId, tab });
};
