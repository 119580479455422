import React, { useEffect, useState } from 'react';

import { FormControl, FormControlLabel, FormHelperText, Typography, TextField as Input, Box, IconButton } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import { useStyles } from './styles';

interface Props {
  name?: string;
  value: any;
  placeholder?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  minRows?: number;
  maxRows?: number;
  type?: 'text' | 'search' | 'number' | 'textarea' | 'password';
  minLength?: number | undefined | null;
  maxLength?: number | undefined | null;
  error?: string;
  helperText?: string;
  autoFocus?: boolean;
  showClear?: boolean;
  className?: string;
  inputRef?: any;
  disabled?: boolean;
  style?: { [key: string]: string };
  onChange: (event: any, value: any) => void;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  onKeyPress?: (event: any) => void;
  onSearchPress?: (value: any) => void;
}

const TextField: React.FC<Props> = (props) => {
  const [type, setType] = useState<string>('text');

  const classes = useStyles({ label: props.label, error: props.error });

  useEffect(() => {
    setType(props.type);
  }, [props?.type]);

  return (
    <FormControl
      className={`${classes.wrapper} ${props.className || ''}`} 
      error={!!props.error} 
      variant="standard"
      style={{ ...props.style }}
    >
      {props.label && (
        typeof props.label === 'string'
          ? <Typography className={classes.label}>
              {props.label} &nbsp;
              {props.required && (
                <Typography style={{ color: 'red' }}>
                  *
                </Typography>
              )}
            </Typography>
          : props.label
      )}
      <FormControlLabel 
        control={
          <Input
            inputRef={props.inputRef}
            variant="outlined"
            multiline={type === 'textarea'}
            minRows={props.minRows || 4}
            maxRows={props.maxRows || 8}
            placeholder={props.placeholder}
            type={type || 'text'}
            className={`
              ${type === 'textarea' ? classes.textarea : classes.input}
              ${props.value?.length ? '' : classes.emptyInput}
            `}
            autoFocus={!!props.autoFocus}
            disabled={props.disabled}
          />
        }
        label={''}
        className={classes.controll} 
        name={props.name}
        value={props.value || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onKeyPress={props.onKeyPress}
      />
      {type === 'search'
        ? props.showClear && props.value.length
          ? <Box className={classes.inputAdornment}>
              <IconButton
                onClick={() => props.onChange({ target: { value: '' } }, '')}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          : <Box className={classes.inputAdornment}>
              <IconButton
                onClick={() => typeof props.onSearchPress === 'function' && props.onSearchPress(props.value)}
              >
                <SearchIcon />
              </IconButton>
            </Box>
        : props.type === 'password'
          ? <Box className={classes.inputAdornment}>
              <IconButton
                onClick={() => setType(type === 'password' ? 'text' : 'password')}
              >
                {type === 'password'
                  ? <VisibilityOffOutlinedIcon />
                  : <VisibilityOutlinedIcon />
                }
              </IconButton>
            </Box>
          : null
      }
      {(props.error?.length || props.helperText?.length || props.maxLength) && (
        <Box
          style={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <FormHelperText error={!!props.error?.length} style={{ maxWidth: '80%' }}>
            {props.error 
              ? props.error
              : props.helperText
            }
          </FormHelperText>
          {props.maxLength && typeof props.maxLength === 'number' && (
            <FormHelperText 
              error={false}
              // style={{
              //   color: props.minLength
              //     ? props.value?.length >= props.minLength
              //       ? 'rgba(0, 0, 0, 0.54)'
              //       : 'rgb(244, 67, 54)'
              //     : 'rgba(0, 0, 0, 0.54)'
              // }}
            >
              {props.value?.length || 0}/{props.maxLength} characters
            </FormHelperText>
          )}
        </Box>
      )}
    </FormControl>
  );
};

export default TextField;
