import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const profileAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountUsers: builder.query<any, any>({
      query: () => ({
        url: "/users",
        method: "GET"
      })
    }),
    inviteAccountUser: builder.mutation<any, any>({
      query: (body) => ({
        url: "/users",
        method: "POST",
        body
      })
    }),
    deleteAccountUser: builder.mutation<any, any>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "DELETE"
      })
    }),
    updateAccountUser: builder.mutation<any, any>({
      query: ({ userId, body }) => ({
        url: `/users/${userId}`,
        method: "PUT",
        body
      })
    }),
    saveNewAccountUserPassword: builder.mutation<any, any>({
      query: ({ userId, body }) => ({
        url: `/users/${userId}/password/temporary`,
        method: "PUT",
        body
      })
    }),
    deactivateAccountUser: builder.mutation<any, any>({
      query: (userId) => ({
        url: `/users/${userId}/deactivate`,
        method: "PUT"
      })
    }),
    activateAccountUser: builder.mutation<any, any>({
      query: (userId) => ({
        url: `/users/${userId}/activate`,
        method: "PUT"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getAccountUsers = async (): Promise<any> => {
  return apiCall(profileAPI.endpoints.getAccountUsers);
};

export const inviteAccountUser = async (body): Promise<any> => {
  return apiCall(profileAPI.endpoints.inviteAccountUser, body);
};

export const deleteAccountUser = async (userId): Promise<any> => {
  return apiCall(profileAPI.endpoints.deleteAccountUser, userId);
};

export const updateAccountUser = async ({ userId, body }): Promise<any> => {
  return apiCall(profileAPI.endpoints.updateAccountUser, { userId, body });
};

export const saveNewAccountUserPassword = async ({
  userId,
  body
}): Promise<any> => {
  return apiCall(profileAPI.endpoints.saveNewAccountUserPassword, {
    userId,
    body
  });
};

export const deactivateAccountUser = async (userId): Promise<any> => {
  return apiCall(profileAPI.endpoints.deactivateAccountUser, userId);
};

export const activateAccountUser = async (userId): Promise<any> => {
  return apiCall(profileAPI.endpoints.activateAccountUser, userId);
};