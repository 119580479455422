import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import CarouselDisplay from '../../blocks/carouseldisplay/main/CarouselDisplay.web';
import { ReactComponent as PebblesFullLogoIcon } from '../../assets/icons/pebbles_full_logo_black.svg';
import { ReactComponent as BackButtonIcon } from '../../assets/icons/back_button.svg';
import { ReactComponent as CanAndGoIcon } from '../../assets/icons/can_and_go.svg';
import { clearStorages } from '../../helpers/other';
import { removeProfile } from '../../redux/features/profile';
import { useDispatch } from 'react-redux';

import { useStyles } from './styles';

const CarouselLayout = (props) => {
  const [carouselType, setCarouselType] = useState<null | "company" | "individual" | "password" | "login" | "welcome" | "b2b-plan">(null);
  const [poweredByLogo, setPoweredByLogo] = useState<boolean>(false);
  const [withScrollContainer, setWithScrollContainer] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<null | string>(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setCarouselType(null);
    setPoweredByLogo(false);
    setWithScrollContainer(false);
    setRedirectPath(null);

    if (props.location.pathname === '/') {
      setPoweredByLogo(true);
      setCarouselType('login');
    }
    if (props.location.pathname === '/login') {
      setPoweredByLogo(true);
      setCarouselType('login');
      setRedirectPath('/');
    }
    if (props.location.pathname === '/forgot-password') {
      setPoweredByLogo(true);
      setCarouselType('password');
      setRedirectPath('/login');
      onLogout();
    }
    if (props.location.pathname === '/choose-option') {
      setPoweredByLogo(true);
      setCarouselType('welcome');
    }
    if (props.location.pathname === '/signup') {
      setPoweredByLogo(true);
      setCarouselType('individual');
      // setRedirectPath('/choose-option');
      setRedirectPath('/');
      onLogout();
    }
    if (props.location.pathname === '/verify-signup-code') {
      setPoweredByLogo(true);
      setWithScrollContainer(true);
      setCarouselType('individual');
    }
    if (props.location.pathname === '/account-details') {
      setPoweredByLogo(false);
      setCarouselType('individual');
    }
    if (props.location.pathname === '/about') {
      setPoweredByLogo(true);
      setCarouselType('individual');
    }
    if (props.location.pathname === '/describle-business-plan') {
      setPoweredByLogo(true);
      setCarouselType('b2b-plan');
    }
    if (props.location.pathname === '/tell-more-business') {
      setPoweredByLogo(true);
      setCarouselType('b2b-plan');
      setRedirectPath('/tell-more-business');
    }
    if (props.location.pathname === '/lets-get-started') {
      setPoweredByLogo(true);
      setCarouselType('company');
      // setRedirectPath('/choose-option');
      setRedirectPath('/');
    }
    if (props.location.pathname === '/company-describe') {
      setPoweredByLogo(true);
      setCarouselType('company');
    }
    if (props.location.pathname === '/company-about') {
      setPoweredByLogo(true);
      setCarouselType('company');
      setRedirectPath('/company-describe');
    }
    if (props.location.pathname === '/password-recovery') {
      setPoweredByLogo(true);
      setCarouselType('password');
      setRedirectPath('/');
      // onLogout();
    }
    if (props.location.pathname === '/new-password') {
      setPoweredByLogo(true);
      setCarouselType('password');
      setRedirectPath('/');
      // onLogout();
    }
    if (props.location.pathname === '/finish-registration') {
      setPoweredByLogo(true);
      setCarouselType('password');
      setRedirectPath('/');
      // onLogout();
    }
    if (props.location.pathname === '/verify-code') {
      setPoweredByLogo(true);
      setCarouselType('individual');
      setRedirectPath('/');
      // onLogout();
    }
  }, [props.location]);

  const onBack = () => {
    props.history.replace(redirectPath);
  }

  const onLogout = async () => {
    await clearStorages();
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.leftSection}>
        <Box className={classes.header}>
          <Box className={classes.logo}>
            <PebblesFullLogoIcon />
          </Box>
          <div className={classes.logo} />
        </Box>
        <Box className={classes.leftContent}>
          <Box
            className={classes.iconBack}
            onClick={onBack}
          >
            {redirectPath && (
              <Box className={classes.back}>
                <BackButtonIcon />
              </Box>
            )}
          </Box>
          <Box className={poweredByLogo ? classes.content2 : classes.content}>
            <Box
              className={withScrollContainer ? classes.scrollContainer : ''}
            >
              {props.children}
            </Box>
          </Box>
        </Box>
        {poweredByLogo && (
          <div
            className={classes.poweredBox}
          >
            <Typography className={classes.poweredText}>Powered by</Typography>
            <Box className={classes.canImg}>
              <CanAndGoIcon />
            </Box>
          </div>
        )}
      </Box>
      <Box className={classes.rightSection}>
        <Box className={classes.carouselWrapper}>
          <CarouselDisplay type={carouselType} />
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(CarouselLayout);