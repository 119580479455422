import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Backdrop, CircularProgress } from "@material-ui/core";
import StorageProvider from "../framework/main/StorageProvider";
import { matchRoute, routeMap } from "../helpers/routes";
import { clearStorages } from "../helpers/other";
import { getProfile, deleteProfile } from "../redux/services/profile";
import { getSubscriptions, getCurrentSubscription } from "../redux/services/subscriptions";
import { enqueueSnackbar } from "notistack";

const AuthenticationProvider = ({ children, location, history }) => {
  const [prevUrl, setPrevUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (routeMap) {
      handleFetchUser();
      handleFetchSubscriptions();
    }
  }, [routeMap, location.pathname]);

  // Handle user profile fetching logic
  const handleFetchUser = useCallback(async () => {
    const token = await StorageProvider.getItem("user-token");
    const nextUrl = location.pathname;
    const prevRoute = Object.values(routeMap).find((route) => matchRoute(prevUrl, route.path));
    const nextRoute = Object.values(routeMap).find((route) => matchRoute(nextUrl, route.path));

    if (
      (prevRoute?.private !== nextRoute?.private) ||
      (prevRoute?.layout !== nextRoute?.layout)
    ) {
      // setLoading(true);

      if (token) {
        await getProfile(true);
      }
    }

    if (nextRoute) {
      await handleNavigation(token, nextRoute, history);
    } else {
      history.push(token ? "/home" : "/");
    }

    setPrevUrl(nextUrl);
    // setLoading(false);
  }, [prevUrl, location.pathname, history]);

  // Handle navigation based on route and user state
  const handleNavigation = async (token, route, history) => {
    if (route.private) {
      if (!token) {
        await clearStorages();

        if (route.path !== "/") {
          history.push("/");
        }
      } else {
        const { data: profile, error: profileError } = await getProfile();
        const { data: subscription, error: subscriptionError } = await getCurrentSubscription();

        if (profile && !profileError) {
          navigateToAppropriatePage(profile, subscription, subscriptionError, route, history);
        } else {
          // await clearStorages();
          // history.push("/");
        }
      }
    } else if (token) {
      const { error } = await getProfile();

      if (!error && route.path !== "/home") {
        history.push("/home");
      }
    }
  };

  // Handle redirection based on user profile state
  const navigateToAppropriatePage = (profile, subscription, subscriptionError, route, history) => {
    if (!profile.account.activated) {
      enqueueSnackbar(
        'Your accound is deactivated',
        { variant: 'error' }
      )

      clearStorages();
      history.push('/');
    } else if (!profile.user.jobRole) {
      history.push("/about-yourself");
    } else if (!subscription && !subscriptionError && route.path !== "/choose-your-plan") {
      history.push("/choose-your-plan");
    }
  };

  // Fetch subscriptions if the user is authenticated
  const handleFetchSubscriptions = useCallback(async () => {
    const token = await StorageProvider.getItem("user-token");

    if (token) {
      await getSubscriptions();
    }
  }, []);

  return loading
    ? <Backdrop style={{ color: "#fff", zIndex: 9999 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    : children
};

// Prevent unnecessary re-renders
const areEqual = (prevProps, nextProps) =>
  prevProps.location.pathname === nextProps.location.pathname &&
  prevProps.history === nextProps.history;

const MemoizedAuthenticationProvider = React.memo(AuthenticationProvider, areEqual);

export const AuthProvider = withRouter(MemoizedAuthenticationProvider);
