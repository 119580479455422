import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Paper, Typography } from "@material-ui/core";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import { removeUrlParams } from "../../helpers/other";
import { getCurrentSubscription } from "../../redux/services/subscriptions";
import { getOnboardingCompleteness } from "../../redux/services/onboarding";
import Header from './components/Header';
import CopilotRef from './components/CopilotRef';
import TacticalOutreachRef from './components/TacticalOutreachRef';
import SalesAssetsRef from './components/SalesAssetsRef';
import MyLibraryRef from './components/MyLibraryRef';
import { getLibraryCampaigns } from "../../redux/services/my-library";
import { observableService } from "../../services/observable";
import { useHistory } from "react-router-dom";

import { useStyles } from './styles';

const DASHBOARD_BLOCK = [
  {
    title: 'ON DEMAND INTELLIGENCE',
    description: 'Ask your personal copilot to generate actionable intelligence, or tactical GTM content.',
    component: <CopilotRef />
  },
  {
    title: 'TACTICAL OUTREACH',
    description: 'Engage your audience with targeted emails using neuromarketing and advanced personalisation.',
    component: <TacticalOutreachRef />
  },
  {
    title: 'SALES ASSETS',
    description: 'Generate compelling 2-pagers, PSI papers, RFPs, and proposals infused with decision-making sciences.',
    component: <SalesAssetsRef />
  }
];

const LandingPage = () => {
  const [myLibraries, setMyLibraries] = useState([]);
  const [licenseState, setLicenseState] = useState({
    showActivateLicense: false,
    showRenewLicense: false,
  });
  const [onboardingState, setOnboardingState] = useState({
    showCompleteOnboardingBaner: false,
    showCompleteOnboardingModal: false,
  });

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const init = async () => {
      const onboardingCompleteness = await getOnboardingCompleteness();
      const subscriptionData = await getCurrentSubscription();

      const showCompleteOnboardingBaner = onboardingCompleteness?.data
        ? Object.values(onboardingCompleteness.data).some((item) => !item)
        : true;

      setOnboardingState((prevState) => ({
        ...prevState,
        showCompleteOnboardingBaner,
      }));

      setLicenseState({
        showActivateLicense: getCurrentSubscriptionStatus(subscriptionData?.data) === "EXPIRED",
        showRenewLicense: getCurrentSubscriptionStatus(subscriptionData?.data) === "CANCELED",
      });

      if (!showCompleteOnboardingBaner) {
        removeUrlParams();
      }

      const libraryData = await getLibraryCampaigns();
      if (libraryData?.data) {
        setMyLibraries(libraryData.data);
      }
    };

    init();

    const subscription = observableService.onEvent().subscribe((data) => {
      if (data === "Check if subscription is active") {
        getCurrentSubscription().then((subscriptionData) => {
          setLicenseState({
            showActivateLicense: getCurrentSubscriptionStatus(subscriptionData?.data) === "EXPIRED",
            showRenewLicense: getCurrentSubscriptionStatus(subscriptionData?.data) === "CANCELED",
          });
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleRedirect = (path) => {
    history.push(path);
  };

  const handleCloseCompleteOnboardingModal = () => {
    removeUrlParams();
    setOnboardingState((prevState) => ({ ...prevState, showCompleteOnboardingModal: false }));
  };

  return (
    <>
      <Container style={{ maxWidth: "100%", paddingBottom: '80px' }}>
        <Box style={{ display: "flex", alignItems: "start" }}>
          <Header
            showActivateLicense={licenseState.showActivateLicense}
            showRenewLicense={licenseState.showRenewLicense}
            showCompleteOnboardingBaner={onboardingState.showCompleteOnboardingBaner}
          />
        </Box>

        <Box
          style={{
            opacity: onboardingState.showCompleteOnboardingBaner || licenseState.showActivateLicense ? 0.5 : 1,
            pointerEvents: onboardingState.showCompleteOnboardingBaner || licenseState.showActivateLicense ? "none" : "all",
          }}
        >
          <Typography className={classes.heading}>
            Dashboard
          </Typography>

          {DASHBOARD_BLOCK.map((item, index) => (
            <Box className={classes.block} key={index}>
              <Paper className={classes.outerPaper} elevation={0}>
                <Grid container className={classes.innerBlock}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                    xl={3}
                    className={classes.leftBlock}
                  >
                    <Box className={classes.leftBlockContent}>
                      <Typography align="left" className={classes.leftBlockTitle}>
                        {item.title}
                      </Typography>

                      <Typography
                        align="left"
                        className={classes.leftBlockDescription}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={9}
                    xl={9}
                    className={classes.rightBlock}
                  >
                    {item.component}
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          ))}

          <Box className={classes.block}>
            <MyLibraryRef />
          </Box>
        </Box>
      </Container>

      <ConfirmationModal
        open={onboardingState.showCompleteOnboardingBaner && onboardingState.showCompleteOnboardingModal}
        title="Fill in onboarding to start using the platform"
        description="Without onboarding you cannot use the platform, because we want to generate the best results for you."
        onClose={handleCloseCompleteOnboardingModal}
        rejectBtnText="Fill in onboarding"
        onReject={() => handleRedirect("/onboarding")}
      />
    </>
  );
};

export default LandingPage;
