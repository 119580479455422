import React from 'react';

import TextField from '../../../../../../components/main/CustomFields/TextField';

import { useStyles } from './styles';
import { Button } from '@material-ui/core';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const RenderUrlInput: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  const classes = useStyles();

  const defaultPaceholder = item.attributes.find(item => item.type === 'PLACEHOLDER_ATTRIBUTE')?.text;
  const placeholder = item.attributes.find(item => (
    item.type === 'RESET_PLACEHOLDER_UNDER_CONDITION_ATTRIBUTE' && values[Object.values(item.elementReference).join('-')] === item.option
  ))?.placeholder;

  return (
    <TextField
      name={name}
      value={values[name]}
      placeholder={placeholder || defaultPaceholder || ''}
      maxLength={item.attributes.find(item => item.type === 'MAX_LENGTH_ATTRIBUTE')?.value}
      error={touched[name] && errors[name]}
      onChange={(event) => setFieldValue(name, event.target.value)}
    />
  );
};

export default RenderUrlInput;
