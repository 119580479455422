import React, { ReactElement } from 'react';
import { Box, Button, Fade, IconButton, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as OnboardingTooltipRobotIcon } from '../../../assets/icons/onboarding_tooltip_robot.svg';

import { useStyles } from './styles';

interface Props {
  children: ReactElement<any, any>,
  open: boolean,
  title: string,
  description: string | React.ReactNode,
  placement: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start",
  onClose: () => void,
}

const OnboardingHelperTooltip: React.FC<Props> = ({
  children,
  open,
  title,
  description,
  placement,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      open={open}
      arrow
      enterDelay={1500}
      leaveDelay={0}
      TransitionComponent={Fade}
      // TransitionProps={{ timeout: 1500 }}
      disableTouchListener
      disableHoverListener
      disableFocusListener
      PopperProps={{
        disablePortal: true,
        keepMounted: false,
        placement: placement,
        // transition: false,
        // modifiers: [
        //   {
        //     name: "preventOverflow",
        //     options: {
        //       mainAxis: false, // true
        //       altAxis: false, // false
        //       padding: 0, // 0
        //       // boundary: Boundary, // "clippingParents"
        //       altBoundary: false, // false
        //       // rootBoundary: RootBoundary, // "viewport"
        //       tether: false, // true
        //       tetherOffset: 0, // 0
        //     },
        //   },
        //   {
        //     name: "flip",
        //     options: {
        //       fallbackPlacements: [],
        //     },
        //   },
        // ],
      }}
      title={
        <>
          <IconButton
            className={classes.tooltipClose}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.tooltipTitle}>
            <OnboardingTooltipRobotIcon />
            {title}
          </Typography>
          <Typography className={classes.tooltipDescription}>
            {description}
          </Typography>
        </>
      }
      classes={{
        popper: classes.tooltipWrapper,
        tooltip: classes.tooltipBox,
        arrow: classes.tooltipArrow
      }}
      placement={placement}
      onClose={onClose}
    >
      {children}
    </Tooltip>
  );
};

export default OnboardingHelperTooltip;
