import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import StorageProvider from "../framework/main/StorageProvider";
import { store } from "./store";
import { ApiResponse } from "./types";
import { clearStorages } from "../helpers/other";

let isRefreshing = false; // Indicates if a token refresh is in progress
let refreshTokenQueue: Array<() => void> = []; // Queue for pending requests

export const api = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    const baseQueryWithAuth = fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL_V2,
      prepareHeaders: async (headers) => {
        const token = await StorageProvider.getItem("user-token");

        if (token) headers.set("Authorization", `Bearer ${token}`);

        return headers;
      }
    });

    let result = await baseQueryWithAuth(args, api, extraOptions);

    if ("error" in result && result.error.status === 401) {
      // If already refreshing, queue this request
      if (isRefreshing) {
        return new Promise((resolve) => {
          refreshTokenQueue.push(() => {
            resolve(baseQueryWithAuth(args, api, extraOptions));
          });
        });
      }

      isRefreshing = true;

      const refresh = await StorageProvider.getItem("user-refresh-token");
      // const refresh = await StorageProvider.getItem("user-token");

      if (refresh) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL_V2}/auth/refresh-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: refresh })
          }
        );
        
        if (response.ok) {
          const data = await response.json();
          await StorageProvider.setItem("user-token", data.accessToken);
          await StorageProvider.setItem("user-refresh-token", data.refreshToken);

          // Retry all queued requests with new token
          refreshTokenQueue.forEach((callback) => callback());
          refreshTokenQueue = [];
          isRefreshing = false;
        } else {
          // Refresh token is invalid or expired
          await clearStorages();
          window.location.href = '/';

          return result; // Return the original error
        }
      } else {
        // No refresh token available, redirect to login
        await clearStorages();
        window.location.href = '/';

        refreshTokenQueue = [];
        isRefreshing = true;
      }

      // Retry the original request
      result = await baseQueryWithAuth(args, api, extraOptions);
    }

    return result;
  },
  tagTypes: ["Common"],
  endpoints: () => ({})
});

export const apiCall = async (
  endpoint: any,
  params: any = null,
  options: any = {
    forceRefetch: true
  }
): Promise<any> => {
  const result = await store.dispatch(endpoint.initiate(params, options));

  const response: ApiResponse = {
    data: result.data
  };

  if ("error" in result) {
    response.error = result.error;
  }

  return response;
};
