import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF'
  },
  container: {
    width: '100%',
    maxWidth: '100%',
    height: '100vh',
    background: 'white',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    minHeight: '70px',
    padding: '15px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#F1F5F9',
  },
  copilot: {
    maxWidth: '880px',
    height: '100%',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    margin: '0 auto',
  },
  messages: {
    minWidth: '700px',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    padding: '20px 20px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  footer: {
    width: '100%',
    maxWidth: '880px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '12px',
    margin: '0 auto'
  },
  headerLeftBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  headerCenterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  headerRightBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gap: '30px'
  },
  tabs: {
    width: 'fit-content',
    padding: '1px',
    border: '1px solid #CBD5E1',
    borderRadius: '80px',
    overflow: 'hidden',
    '& .MuiButtonBase-root': {
      height: '30px',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '22px',
      borderRadius: '80px',
      borderColor: 'transparent !important',
      padding: '0px 12px'
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  footerSuggestions: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  footerInput: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    border: '1px solid #94A3B8',
    padding: '0px 12px 0px 12px',
    borderRadius: '8px',
    '& .MuiInput-root': {
      minHeight: '44px',
      border: 'none',
      padding: '0px',
      '& textarea': {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '21px',
        // fontStyle: 'italic'
      }
    },
    '& ::before': {
      display: 'none'
    },
    '& ::after': {
      display: 'none'
    }
  },
  suggestion: {
    width: '100%',
    minWidth: '130px',
    maxWidth: '280px',
    minHeight: '82px',
    maxHeight: '82px',
    background: 'white',
    padding: '20px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#F1F5F9'
    }
  },
  messagesItem: {
    display: 'flex',
    gap: '10px',
  },
  comingSoon: {
    height: '45px !important',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  welcomeContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#F8FAFC',
    padding: '28px',
    borderRadius: '28px',
    gap: '10px',
    margin: '0 auto 64px auto'
  },
  welcomeBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  welcomeText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '17px',
    color: '#64748B',
  },
  chatTitle: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  divider: {
    width: '1px',
    height: '100%',
    background: '#94A3B8',
  },
  leverageBox: {
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  leverageText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#475569',
  },
  list: {
    paddingInlineStart: '17px',
    margin: '0px',
  },
  listItem: {
    marginBottom: '4px',
  },
  listItemText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: '#475569',
  },
  listItemTextSecondary: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: '#94A3B8',
  },
  soonBox: {
    width: '1px',
    height: '21px',
    background: '#94A3B8',
  },
  comingSoonText: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '18px',
  },
}));
