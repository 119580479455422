import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  pebble: {
    position: "relative" as "relative",
    padding: "25px 20px",
    borderRadius: "20px",
    boxSizing: "border-box",
    cursor: "pointer"
  },

  pebbleTitle: {
    fontSize: 16,
    lineHeight: "19.2px",
    fontWeight: 600
  },

  pebbleContent: {
    marginTop: 60
  },

  pebbleImage: {
    position: "absolute" as "absolute",
    top: 0,
    right: 0,
    width: 200,
    height: "auto",

    "@media (max-width: 767.98px)": {
      width: 156,
      top: -20,
      right: -20
    }
  },

  pebbleDescription: {
    marginTop: 8,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "14.4px",
    color: "#475569"
  },

  arrowIcon: {
    transform: "rotate(-45deg)"
  }
}));
