import React from 'react';
import { Box, Button, FormHelperText, Grid, IconButton, RadioGroup, Typography } from "@material-ui/core";
import { ReactComponent as InfoHintMiniIcon } from '../../../../../../assets/icons/info_hint_mini.svg';
import { ReactComponent as ClearIcon } from '../../../../../../assets/icons/close.svg';
import RadioButton from '../../../../../../components/main/CustomFields/RadioButton';
import SubSectionElement from '../..';
import { findElementByName, generateValidationRules, isFieldNotEmpty } from '../../../../helpers';
import { COUNTRIES_FLAG } from '../../../../../../utils/countries';
import * as Yup from 'yup';

import { useStyles } from './styles';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  validationSchema: any;
  template: any;
  uukey: string;
  setValues: (values: any, shouldValidate?: boolean) => void;
  setErrors: (errors: any) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setValidationSchema: (shape: any) => void;
  setTooltipMessage: (message: string) => void;
}

const RenderRadioButtons: React.FC<Props> = ({
  item,
  name,
  values,
  // errors,
  // touched,
  validationSchema,
  template,
  uukey,
  setValues,
  setErrors,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  setValidationSchema,
  setTooltipMessage,
  ...props
}) => {
  const classes = useStyles();

  const errors = (uukey ? props.errors[uukey] : props.errors) || {}
  const touched = (uukey ? props.touched[uukey] : props.touched) || {}

  const handleSetValue = (name: string, value: any, element?: any) => {
    let newValidationSchema = { ...validationSchema };
    const keyPrefix = uukey ? uukey : name;
  
    // Helper to clear field values
    const clearValues = (valuesObj: any, setValue: Function) => {
      Object.keys(valuesObj).forEach((key) => {
        if (key.startsWith(name) && name !== key) {
          const newValues = { ...values };

          if (uukey) {
            delete newValues[uukey][key];
          } else {
            delete newValues[key];
          }

          setValues(newValues);
        }
      });
    };
  
    // Helper to clear field errors
    const clearErrors = (errorsObj: any, setError: Function) => {
      Object.keys(errorsObj || {}).forEach((key) => {
        if (key.startsWith(name) && name !== key) {
          const newErrorValue = uukey ? { ...errorsObj, [key]: undefined } : undefined;
          setError(uukey ? uukey : key, newErrorValue);
        }
      });
    };

    // Helper to clear field touched
    // const clearTouched = (touchedObj: any, setTouched: Function) => {
    //   Object.keys(touchedObj || {}).forEach((key) => {
    //     if (key.startsWith(name) && name !== key) {
    //       setTouched(uukey ? uukey : key, false);
    //     }
    //   });
    // };
  
    // Helper to clear validation schema
    const clearValidationSchema = (schema: any, removeNode = true) => {
      Object.keys(schema).forEach((key) => {
        if (key.startsWith(name) && name !== key) {
          if (uukey) {
            delete schema[uukey].fields[key];
            if (removeNode) {
              schema[uukey]._nodes = schema[uukey]._nodes.filter(item => item !== key);
            }
          } else {
            delete schema[key];
          }
        }
      });
    };
  
    // Clear values, errors, and validation schema
    clearValues(uukey ? values[uukey] : values, setFieldValue);
    clearErrors(uukey ? props.errors[uukey] : props.errors, setFieldError);
    // clearTouched(uukey ? props.touched[uukey] : props.touched, setFieldTouched);
    clearValidationSchema(newValidationSchema, true);

    // Add new validation rules if needed
    if (value.length && element?.element?.id) {
      const nestedName = `${name}-${element.element.id}`;
      const rules = generateValidationRules(nestedName, element.element, item);
      
      if (uukey) {
        newValidationSchema[uukey].fields[nestedName] = rules;
        newValidationSchema[uukey]._nodes = [...newValidationSchema[uukey]._nodes, nestedName];

        // if (element?.element.type === 'TEXT_AREA') {
        //   setFieldTouched(`${uukey}.${nestedName}`, true);
        // }
      } else {
        newValidationSchema[nestedName] = rules;

        // if (element?.element.type === 'TEXT_AREA') {
        //   setFieldTouched(nestedName, true);
        // }
      }
    }

    // Update value and validation schema
    setFieldValue(uukey ? `${uukey}.${name}` : name, value);
    setValidationSchema(newValidationSchema);
  };

  const renderOptions = (item: any, name: string) => {
    const newOptionsAttributes = item?.attributes?.filter(attr => attr.type === "RESET_OPTIONS_UNDER_CONDITION_ATTRIBUTE");
    const targetAttribute = newOptionsAttributes?.find(item => item.option === values[Object.values(item.elementReference).join('-')]);
    let options = [];

    if (targetAttribute) {
      options = targetAttribute.newOptions;

      if (isFieldNotEmpty(values[name]) && !options.includes(values[name])) {
        setFieldValue(name, '');
        setFieldError(name, '');
      }
    } else {
      options = item.options;
    }

    return options.map((option: any, index: number) => {
      const element = item.attributes?.find(attr => attr.type === 'ON_OPTION_CHILD_ATTRIBUTE' && attr.option === option);
      const selected = !!(uukey
        ? values[uukey] && values[uukey][name] === option
        : values[name] === option)
      const disabled = !!item.attributes?.find(item => item.type === 'DEFINED_OPTION_ATTRIBUTE');

      return (
        <Box
          key={index}
          style={selected && element?.element?.type === 'TEXT_AREA' ? {
            position: 'relative',
            padding: '8px',
            background: '#F3F3F3',
            borderRadius: '8px',
          } : {}}
        >
          {selected && element?.element?.type === 'TEXT_AREA' && (
            <Box className={classes.nestedSeparator} />
          )}
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <RadioButton
              name={name}
              label={
                <>
                  <img src={COUNTRIES_FLAG[option]} alt="" />&nbsp;
                  {option}&nbsp;
                  {item.attributes?.find(attr => attr.type === 'ON_OPTION_TOOLTIP_ATTRIBUTE')?.optionTooltipMap[option] && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        const tooltip = item.attributes?.find(attr => attr.type === 'ON_OPTION_TOOLTIP_ATTRIBUTE')?.optionTooltipMap[option];
                        setTooltipMessage(tooltip);
                      }}
                    >
                      <InfoHintMiniIcon />
                    </IconButton>
                  )}
                </>
              }
              checked={selected}
              disabled={disabled}
              onChange={(event) => handleSetValue(event.target.name, option, element)}
            />
            {!disabled && selected && (
              <Button
                variant="text"
                startIcon={<ClearIcon />}
                onClick={() => handleSetValue(name, '')}
                style={{
                  height: '1px',
                  minHeight: '1px',
                  maxHeight: '1px',
                  padding: '0px 8px',
                }}
              >
                Clear
              </Button>
            )}
          </Box>
          {selected && element && (
            <>
              <Box
                className={`
                  ${classes.grouppedRadioButtonWrapper}
                  ${(element.element.groups?.length || element.element.options?.length || element.element) ? classes.paddings : ''}
                  ${element.element.groups?.length ? classes.gaps : ''}
                `}
                style={{
                  flexDirection: element.element.groups?.length ? 'row' : 'column',
                  boxSizing: !element.element.groups?.length && !element.element.options?.length ? 'border-box' : 'content-box',
                }}
              >
                {element.element.groups?.length
                  ? element.element.groups.map((group, index) => (
                      <Box key={index} className={classes.grouppedRadioButton}>
                        {group.label && (
                          <Typography style={{ fontSize: '12px', fontWeight: '600', lineHeight: '14px', color: '#64748B' }}>
                            {group.label}
                          </Typography>
                        )}
                        <RadioGroup>
                          {renderOptions(group, `${name}-${element.element.id}`)}
                        </RadioGroup>
                      </Box>
                    ))
                  : element.element.options?.length
                    ? <>
                        {renderOptions(element.element, `${name}-${element.element.id}`)}
                        {renderErrorMessage(`${name}-${element.element.id}`, false)}
                      </>
                    : (
                        <SubSectionElement
                          parent={element}
                          item={element.element}
                          name={`${name}-${element.element.id}`}
                          values={values}
                          errors={props.errors}
                          touched={props.touched}
                          validationSchema={validationSchema}
                          template={template}
                          uukey={uukey}
                          setValues={setValues}
                          setErrors={setErrors}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          setFieldTouched={setFieldTouched}
                          setValidationSchema={setValidationSchema}
                          setTooltipMessage={setTooltipMessage}
                        />
                      )}
              </Box>
              {!!element.element.groups?.length && (
                <Box className={classes.paddings} style={{ paddingTop: '0px' }}>
                  {renderErrorMessage(`${name}-${element.element.id}`, false)}
                </Box>
              )}
            </>
          )}
        </Box>
      );
    });
  };

  const renderErrorMessage = (name: string, touchedRequired = true) => (
    (touchedRequired ? touched[name] : true) && errors[name] && (
      <FormHelperText error>
        {errors[name]}
      </FormHelperText>
    )
  );

  return (
    <>
      <RadioGroup style={{ flexWrap: 'nowrap' }}>
        {renderOptions(item, name)}
      </RadioGroup>
      {renderErrorMessage(name, true)}
    </>
  );
};

export default RenderRadioButtons;
