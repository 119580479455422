import * as Yup from 'yup';

export const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validationSchema = Yup.object({
  // emailInput: Yup.string()
  //   .test(
  //     'is-valid-emails',
  //     'One or more emails are invalid',
  //     value => value?.split(',').every(email => email.trim() === '' || Yup.string().email().isValidSync(email.trim()))
  //   )
  //   .required('At least one email is required'),
  // emails: Yup.array()
  //   .of(Yup.string().email("Invalid email."))
  //   .required('At least one email is required.'),
  email: Yup.string()
    .email("Email address is not valid.")
    .required("This field is required"),
  name: Yup.string()
    .matches(/^[a-zA-Z\s\-']+$/, 'Full name can only contain letters, spaces, hyphens, and apostrophes')
    .test(
      'min-words',
      'Enter correct full name',
      value => value?.length && value?.split(' ').filter(word => word.length >= 3).length >= 2
    )
    .max(1000, 'Max 1000 symbols')
    .required("This field is required"),
  // projects: Yup.array()
  //   .of(Yup.string())
  //   .required('This field is required'),
  permission: Yup.string()
    .nullable()
    .required('This field is required'),
  // message: Yup.string()
  //   .max(1000, 'Max 1000 symbols')
  //   .required('This field is required'),
});
