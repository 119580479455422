import React from 'react';
import { Avatar, Box, Button, Checkbox, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import CheckboxCheckedIcon from '../../../../assets/icons/checkbox_checked.svg';
import CheckboxIcon from '../../../../assets/icons/checkbox.svg';
import Dropdown from '../../../../components/main/CustomFields/Dropdown';
import { PERMISSIONS } from '../../../../utils/enums';
import { ReactComponent as BackButtonIcon } from '../../../../assets/icons/back_button_unstroked.svg';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';

interface Props {

}

const UserById: React.FC<Props> = (props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box
      style={{
        maxWidth: '1280px',
        minWidth: '880px',
        padding: '92px 80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '15px',
        background: 'white'
      }}
    >
      <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '12px', transform: 'translate(-56px, 0)' }}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <IconButton 
              size='small'
              onClick={() => history.push('/user-management')}
            >
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30px', height: '30px' }}>
                <BackButtonIcon />
              </Box>
            </IconButton>
            <Avatar
              alt="Remy Sharp"
              src="https://yourbasic.org/golang/square-gopher.png"
              style={{ width: '48px', height: '48px' }}
            />
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <Typography style={{ fontSize: '24px', fontWeight: '600', lineHeight: '29px' }}>
              Viktor Machko
            </Typography>
            <Typography style={{ fontSize: '16px', fontWeight: '400', lineHeight: '19px', color: '#475569' }}>
              viktoriia@trypebbles.ai
            </Typography>
          </Box>
        </Box>
        {/* <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={true}
            checkedIcon={<img width={20} height={20} src={CheckboxCheckedIcon} />} //custom checknbox
            icon={<img width={20} height={20} src={CheckboxIcon} />} //customcheckbox icon
            onClick={() => { }}
          />
          <Typography style={{ fontSize: '16px', fontWeight: '500', lineHeight: '24px' }}>
            Super Admin
          </Typography>
        </Box> */}
      </Box>
      <TableContainer
        component={Box}
        style={{
          width: '100%',
          boxShadow: 'none'
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell 
                align="center"
                style={{
                  width: '32px',
                  padding: '16px 0px'
                }}
              >
                <Avatar
                  style={{ width: '32px', height: '32px' }}
                />
              </TableCell>
              <TableCell align="left">
                <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                  Coca Cola
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  width: '250px',
                  padding: '16px 0px'
                }}
              >
                <Dropdown
                  required
                  name={'permissions'}
                  value={'Review'}
                  placeholder={'Choose option...'}
                  options={Object.values(PERMISSIONS).map(item => ({ value: item, label: item }))}
                  onChange={(event, value) => {}}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          color='secondary'
          variant='outlined'
          onClick={() => { }}
        >
          Assign to project
        </Button>
      </Box>
    </Box>
  );
};

export default UserById;
