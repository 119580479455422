import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import { Box, Button, FormHelperText, IconButton, Tooltip } from "@material-ui/core";
import TextField from "../../../../components/main/CustomFields/TextField";
import { ReactComponent as LibraryBooksIcon } from '../../../../assets/icons/library_books.svg';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_red.svg';
import { ReactComponent as ReloadIcon } from '../../../../assets/icons/reload_icon.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy_email_content.svg';
import { deleteLibraryEmail, duplicateLibraryEmail } from "../../../../redux/services/my-library";
import { enqueueSnackbar } from "notistack";
import ConfirmationModal from "../../../../components/main/ConfirmationModal";
import Bold from '../../../../assets/editor/bold.svg';
import Italic from '../../../../assets/editor/italic.svg';
import Underline from '../../../../assets/editor/underline.svg';
import Strikethrough from '../../../../assets/editor/strikethrough.svg';
import ListBulleted from '../../../../assets/editor/list_bulleted.svg';
import ListNumbered from '../../../../assets/editor/list_numbered.svg';
import AlignLeft from '../../../../assets/editor/align_left.svg';
import AlignCenter from '../../../../assets/editor/align_center.svg';
import AlignRight from '../../../../assets/editor/align_right.svg';
import InsertLink from '../../../../assets/editor/insert_link.svg';
import RemoveLink from '../../../../assets/editor/remove_link.svg';
import FormatClear from '../../../../assets/editor/format_clear.svg';
import Table from '../../../../assets/editor/table.svg';
import { regenerateEmail, triggerEmailGenerate } from "../../../../redux/services/tactical-outreach";
import { observableService } from "../../../../services/observable";
import { convertHTMLToText, convertStateToHTML, copyHtmlToClipboard } from "../../helpers";

import { useStyles } from "./styles";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';

const customStyleMap = {
  'FONT_SIZE_8': { fontSize: '8px' },
  'FONT_SIZE_9': { fontSize: '9px' },
  'FONT_SIZE_10': { fontSize: '10px' },
  'FONT_SIZE_11': { fontSize: '11px' },
  'FONT_SIZE_12': { fontSize: '12px' },
  'FONT_SIZE_14': { fontSize: '14px' },
  'FONT_SIZE_16': { fontSize: '16px' },
  'FONT_SIZE_18': { fontSize: '18px' },
  'FONT_SIZE_24': { fontSize: '24px' },
  'FONT_SIZE_30': { fontSize: '30px' },
  'FONT_SIZE_36': { fontSize: '36px' },
  'FONT_SIZE_48': { fontSize: '48px' },
  'FONT_SIZE_60': { fontSize: '60px' },
  'FONT_SIZE_72': { fontSize: '72px' },
  'FONT_SIZE_96': { fontSize: '96px' },
};

const ContentEditor = ({
  campaign,
  email,
  editorState,
  setEmail,
  isSaveDisabled,
  handleSaveEmail,
  handleGetCampaign,
  setEditorState,
  setLoading,
  setShowStopGeneration,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();

  const handleDeleteEmail = async () => {
    setLoading(true);
    setShowDeleteModal(false);

    const { error } = await deleteLibraryEmail({ campaignId: campaign.id, emailId: email.id });

    if (!error) {
      setEmail(null);
      handleGetCampaign(campaign.id, null);

      enqueueSnackbar(
        'Email deleted successfully',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Failed to delete',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  const handleDuplicateEmail = async () => {
    setLoading(true);

    const { data, error } = await duplicateLibraryEmail({ campaignId: campaign.id, emailId: email.id });

    if (data && !error) {
      handleGetCampaign(data.campaignId, data.id);

      enqueueSnackbar(
        'Duplicated successfully',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Failed to duplicate',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  const handleRegenerateEmail = async () => {
    setLoading(true);

    const isMultiTouchCampaign = campaign.subCategory === 'MULTI_TOUCH_CAMPAIGNS';

    const { data, error } = await regenerateEmail({
      type: isMultiTouchCampaign 
        ? 'multi_touch' 
        : 'single_touch',
        emailId: email.id
    });

    if (data && !error) {
      observableService.sendEvent('Increase usage amount');

      enqueueSnackbar(
        'The email content was re-generated successfully',
        { variant: 'success' }
      );

      handleGetCampaign(data.campaignId, email.id);
    } else {
      if (error?.status === 402) {
        observableService.sendEvent('Show top up modal for TACTICAL_OUTREACH');
      } else {
        observableService.sendEvent('Decrease usage amount');

        enqueueSnackbar(
          'Failed to re-generate email',
          { variant: 'error' }
        );
      }
    }

    setLoading(false);
  }

  const handleCopy = async () => {
    const html = convertStateToHTML(editorState);

    const isSuccessully = await copyHtmlToClipboard(html);

    isSuccessully
      ? enqueueSnackbar(
          'Copied to clipboard',
          { variant: 'info' }
        )
      : enqueueSnackbar(
          'Failed to copy',
          { variant: 'error' }
        )
  }

  return (
    <>
      <Box
        className={classes.content}
        style={
          campaign.subCategory === 'MULTI_TOUCH_CAMPAIGNS' && campaign.status === 'INCOMPLETED'
            ? {
              opacity: '0.5',
              pointerEvents: 'none'
            }
            : {}
        }
      >
        <TextField
          value={email.subject || ''}
          placeholder={'Subject line'}
          label={'Subject line'}
          error={!email?.subject?.length ? 'This field is required' : ''}
          onChange={(event) => setEmail({ ...email, subject: event.target.value })}
        />
        <Box className={classes.editor}>
          {editorState && (
            <Editor
              editorState={editorState}
              customStyleMap={customStyleMap}
              // contentState={{}}
              toolbar={{
                options: [
                  'blockType',
                  // 'fontSize', 
                  'inline',
                  'list',
                  'textAlign',
                  'link',
                  // 'emoji',
                  'remove',
                  // 'history'
                ],
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                  className: classes.toolbarButton,
                  component: undefined,
                  // dropdownClassName: classes.toolbarButton,
                },
                fontSize: {
                  // icon: fontSize,
                  options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                  className: classes.toolbarButton,
                  component: undefined,
                  // dropdownClassName: classes.toolbarButton,
                },
                inline: {
                  inDropdown: false,
                  className: classes.toolbarGroup,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                  bold: { icon: Bold, className: classes.toolbarButton },
                  italic: { icon: Italic, className: classes.toolbarButton },
                  underline: { icon: Underline, className: classes.toolbarButton },
                  strikethrough: { icon: Strikethrough, className: classes.toolbarButton },
                },
                textAlign: {
                  inDropdown: false,
                  className: classes.toolbarGroup,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['left', 'center', 'right'],
                  left: { icon: AlignLeft, className: classes.toolbarButton },
                  center: { icon: AlignCenter, className: classes.toolbarButton },
                  right: { icon: AlignRight, className: classes.toolbarButton },
                },
                list: {
                  inDropdown: false,
                  className: classes.toolbarGroup,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['ordered', 'unordered'],
                  ordered: { icon: ListNumbered, className: classes.toolbarButton },
                  unordered: { icon: ListBulleted, className: classes.toolbarButton }
                },
                link: {
                  inDropdown: false,
                  className: classes.toolbarGroup,
                  component: undefined,
                  popupClassName: undefined,
                  dropdownClassName: undefined,
                  showOpenOptionOnHover: true,
                  defaultTargetOption: '_self',
                  options: ['link', 'unlink'],
                  link: { icon: InsertLink, className: classes.toolbarButton },
                  unlink: { icon: RemoveLink, className: classes.toolbarButton },
                  linkCallback: undefined
                },
                emoji: {
                  // icon: emoji,
                  className: classes.toolbarButton,
                  component: undefined,
                  popupClassName: undefined,
                  emojis: [
                    '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                    '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                    '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                    '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                    '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                    '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                    '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                    '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                    '✅', '❎', '💯',
                  ],
                },
                remove: {
                  icon: FormatClear,
                  className: classes.toolbarButton,
                  component: undefined
                },
                history: {
                  inDropdown: false,
                  className: classes.toolbarGroup,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['undo', 'redo'],
                  undo: { className: classes.toolbarButton },
                  redo: { className: classes.toolbarButton },
                },
              }}
              // toolbarCustomButtons={[<CustomOption />]}
              handleReturn={(event) => {
                // Check if Shift key is pressed along with Enter
                if (event.shiftKey) {
                  // Prevent the default action (inserting a line break)
                  return 'handled';
                }

                return 'not-handled';
              }}
              handlePastedText={(text, html, editorState) => { }}
              onEditorStateChange={(event) => setEditorState(event)}
              wrapperClassName={`wrapper-class ${classes.editorWrapper}`}
              toolbarClassName={`toolbar-class ${classes.editorToolbar}`}
              editorClassName={`editor-class ${classes.editorTextarea}`}
            />
          )}
        </Box>
        {!convertHTMLToText(convertStateToHTML(editorState))?.length && (
          <FormHelperText
            error
            style={{
              marginTop: '-17px'
            }}
          >
            This field is required
          </FormHelperText>
        )}
      </Box>
      <Box className={classes.footer}>
        <Box className={classes.actions}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSaveDisabled() || !email?.subject?.length || !convertHTMLToText(convertStateToHTML(editorState))?.length}
            endIcon={<LibraryBooksIcon style={{ color: '#3A84C9' }} />}
            onClick={() => {
              if (campaign.status === 'INCOMPLETED') {
                setShowStopGeneration(true);
              } else {
                handleSaveEmail();
              }
            }}
          >
            Save
          </Button>
          {navigator.clipboard && window.ClipboardItem && (
            <>
              <Tooltip title="Copy content" arrow>
                <IconButton
                  onClick={() => {
                    if (campaign.status === 'INCOMPLETED') {
                      setShowStopGeneration(true);
                    } else {
                      handleCopy();
                    }
                  }}
                >
                  <CopyIcon style={{ color: 'black' }} />
                </IconButton>
              </Tooltip>
              <Box className={classes.varticalSeparator} />
            </>
          )}
          <Tooltip title="Duplicate email" arrow>
            <IconButton
              onClick={() => {
                if (campaign.status === 'INCOMPLETED') {
                  setShowStopGeneration(true);
                } else {
                  handleDuplicateEmail();
                }
              }}
            >
              <DuplicateIcon style={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Re-generate content & subject" arrow>
            <IconButton
              onClick={() => {
                if (campaign.status === 'INCOMPLETED') {
                  setShowStopGeneration(true);
                } else {
                  handleRegenerateEmail()
                }
              }}
            >
              <ReloadIcon style={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
          <Box className={classes.varticalSeparator} />
          <Tooltip title="Delete email" arrow>
            <IconButton
              disabled={campaign?.emails?.length <= 1}
              onClick={() => {
                if (campaign.status === 'INCOMPLETED') {
                  setShowStopGeneration(true);
                } else {
                  setShowDeleteModal(true)
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <ConfirmationModal
        open={showDeleteModal}
        title={"Are you sure you want to delete this email?"}
        description={"This action is irreversible"}
        onClose={() => setShowDeleteModal(false)}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteEmail}
      />
    </>
  );
};

export default ContentEditor;
