import React, { useEffect, useState } from "react";
import { useStyles } from './styles';
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Paper, Grid } from "@material-ui/core";
import { trimTextByCharacters } from "../../../../helpers/text";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { getLibraryCampaigns } from "../../../../redux/services/my-library";

const MyLibraryRef = () => {
  const [campaigns, setCampaigns] = useState<any>([]);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    handleGetCampaigns();
  }, []);

  const handleGetCampaigns = async () => {
    const { data, error } = await getLibraryCampaigns();

    if (data && !error) {
      setCampaigns(data);
    } else {
      setCampaigns([]);
    }
  }

  return (
    <Paper className={classes.container} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className={classes.list}>
            <Typography className={classes.title}>
              MY HISTORY
            </Typography>
            <Box className={classes.content}>
              {[...campaigns].filter(item => item.emails.length && item.status === 'COMPLETED')?.slice(0, 3).map((item: any, index: number) => (
                <Box className={classes.flow} key={index}>
                  <Box className={classes.flowInner}>
                    <Box className={classes.flowHead}>
                      {/* <img className={classes.icon} src={handelColorInLiastData2(item.campaign_category_id, bookBlue, bookRed, bookGreen)} /> */}
                      <Typography className={classes.flowTitle}>
                        {trimTextByCharacters(item.name, 50)}
                      </Typography>
                    </Box>

                    <Typography className={classes.flowDescription}>
                      {trimTextByCharacters(item.description, 100)}
                    </Typography>
                  </Box>

                  <Button
                    color="secondary"
                    variant="outlined"
                    style={{
                      minWidth: 'fit-content'
                    }}
                    onClick={() => history.push(`/tactical-outreach/email?campaign_id=${item.id}`)}
                  >
                    View
                  </Button>
                </Box>
              ))}
            </Box>
            {!campaigns.length
              ? <Typography className={classes.noData}>No Data</Typography>
              : <Button
                  fullWidth
                  // className={classes.button}
                  color="secondary"
                  variant="outlined"
                  endIcon={
                    <ArrowForwardIcon
                      className={classes.arrowIcon}
                      fontSize="small"
                    />
                  }
                  onClick={() => history.push('/my-history')}
                >
                  View all
                </Button>
            }
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MyLibraryRef;
