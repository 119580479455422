import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '670px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '40px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    outline: 'none'
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabs: {
    display: 'flex',
    padding: '2px',
    border: '1px solid #CBD5E1',
    borderRadius: '80px',
    background: '#F8FAFC',
    gap: '8px'
  },
  tabItem: {
    padding: '4px 12px',
    borderRadius: '80px',
    background: 'transparent',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '#000000',
    cursor: 'pointer',
  },
  tabItemActive: {
    background: 'black',
    color: 'white'
  },
  content: {
    width: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}));