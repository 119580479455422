import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { useStyles } from './styles';

const OnboardingLayout = (props) => {
  const classes = useStyles();

  return (
    <Box style={{ display: "flex", width: "100%", overflowX: 'hidden', overflowY: 'auto', zIndex: '9999' }}>
      <main className={classes.content}>
        {props.children}
      </main>
    </Box>
  );
};

export default withRouter(OnboardingLayout);