import React, { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, IconButton, Modal } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import Profile from './components/Profile';
import PlanAndBilling from './components/PlanAndBilling';
import Wallet from './components/Wallet';
import { getProfile } from '../../../redux/services/profile';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onReloadData: () => void;
}

const ProfileModal: React.FC<Props> = ({
  open,
  onClose,
  onReloadData,
}) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [hideParent, setHideParent] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      getProfile(true);
    }

    return () => {
      setActiveTab(1)
    }
  }, [open]);

  return (
    <>
      <Backdrop
        style={{
          color: '#fff', zIndex: 99999
        }}
        open={hideParent}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {open && (
        <Modal 
          open={open} 
          onClose={onClose} 
          className='MuiDialog-root'
          style={{
            visibility: hideParent ? 'hidden' : 'visible'
          }}
        >
          <Box className={classes.container}>
            <Box className={classes.header}>
              <Box className={classes.tabs}>
                <Box
                  className={`${classes.tabItem} ${activeTab === 1 ? classes.tabItemActive : ''}`}
                  onClick={() => setActiveTab(1)}
                >
                  Profile
                </Box>
                {/* <Box 
                  className={`${classes.tabItem} ${activeTab === 2 ? classes.tabItemActive : ''}`}
                  onClick={() => setActiveTab(2)}
                >
                  Settings
                </Box> */}
                <Box
                  className={`${classes.tabItem} ${activeTab === 3 ? classes.tabItemActive : ''}`}
                  onClick={() => setActiveTab(3)}
                >
                  Plan and Billing
                </Box>
                <Box
                  className={`${classes.tabItem} ${activeTab === 4 ? classes.tabItemActive : ''}`}
                  onClick={() => setActiveTab(4)}
                >
                  Pebbles Wallet
                </Box>
              </Box>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.content}>
              {activeTab === 1 && (
                <Profile
                  onClose={onClose}
                  onReloadData={onReloadData}
                />
              )}
              {/* {activeTab === 2 && (
                <Settings2
                  reloadData={onReloadData}
                  navigation={{}}
                  handleToClose={onClose}
                />
              )} */}
              {activeTab === 3 && (
                <PlanAndBilling 
                  setHideParent={(hide: boolean) => setHideParent(hide)}
                />
              )}
              {activeTab === 4 && (
                <Wallet
                  hideParent={hideParent}
                  setHideParent={(hide: boolean) => setHideParent(hide)}
                />
              )}
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ProfileModal;
