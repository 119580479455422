import { makeStyles } from "@material-ui/core";
import LandingHeaderBg from '../../../../assets/images/landing_header_background.jpg';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: 235,
    backgroundImage: `url(${LandingHeaderBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    overflow: "hidden",

    "@media (min-width: 768px)": {
      borderRadius: "0px 0px 20px 20px"
    }
  },

  inner: {
    width: "fit-content",
    height: "100%",
    padding: "26px 20px",
    display: "flex",
    flexDirection: "column",
    background: "#00000099",
    boxSizing: "border-box",
    backdropFilter: "blur(15px)"
  },

  subHeading: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "11px",
    color: "#FFFFFF",
    marginTop: 26,
    letterSpacing: "1px"
  },

  heading: {
    fontWeight: 600,
    fontSize: 19,
    lineHeight: "21px",
    color: "#FFFFFF",
    marginTop: 9
  },

  description: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: "13px",
    color: "#FFFFFF",
    marginTop: "auto",
    letterSpacing: "0em"
  },
  fillOnboarding: {
    width: "fit-content",
    height: "fit-content",
    marginTop: "auto",
    background: "transparent !important",
    padding: "7px 16px 7px 21px",
    "& .MuiButton-label": {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "16px"
    }
  }
}));
