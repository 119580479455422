import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: (props: any) => `url(${props.background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  pwdMsg: {
    margin: "10px  0px",
    fontWeight: 700,
    fontSize: "30px"
  },
  pwdMsg2: {
    // margin: '16px  0px',
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)"
  },
  mainform: {
    width: "80%",
    maxWidth: "670px",
    borderRadius: "28px",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: " space-around",
    alignItems: "center",
    background: "#EBD8C1"
    // background:
    //   "linear-gradient(0deg, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72))"
  },
  LogInNowBtn: {
    margin: "25px 0px ",
    color: "white",
    backgroundColor: "black",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px"
  }
}));
