import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Avatar, Backdrop, Box, Button, Checkbox, CircularProgress, Container, FormControl, IconButton, InputAdornment, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import { AvatarGroup, Pagination } from '@material-ui/lab';
import { ReactComponent as RightBarIcon } from '../../assets/icons/right_bar_icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as AddUserIcon } from '../../assets/icons/plus_user.svg';
import { ReactComponent as NoticationIcon } from '../../assets/icons/notification.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/chat.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../assets/icons/dropdown_down.svg';
import { ReactComponent as SortInactiveIcon } from '../../assets/icons/sort_inactive.svg';
import CheckBoxIcon from '../../assets/icons/checkbox.svg';
import CheckBoxCheckedIcon from '../../assets/icons/checkbox_checked.svg';
import InviteUserModal from './components/InviteUserModal';
import { PERMISSIONS } from '../../utils/enums';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import { activateAccountUser, deactivateAccountUser, deleteAccountUser, getAccountUsers, inviteAccountUser, updateAccountUser } from '../../redux/services/user-management';
import { enqueueSnackbar } from 'notistack';
import { lastActivityDateFormat } from '../../helpers/date';
import TextField from '../../components/main/CustomFields/TextField';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dropdown from '../../components/main/CustomFields/Dropdown';

import { useStyles } from './styles';
import { getProfile } from '../../redux/services/profile';

interface Props {
  navigation: any;
}

const UserManagement: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any[] | null>();
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [showInviteUserModal, setShowInviteUserModal] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(null);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    handleGetProfile();
  }, []);

  useEffect(() => {
    if (profile) {
      handleGetUsers();
    }
  }, [profile]);

  const handleGetUsers = async () => {
    setLoading(true);

    const { data, error } = await getAccountUsers();

    if (data && !error) {
      let newUsers = data.filter(item => item.email !== profile?.account?.email);
      const originalUser = data.find(item => item.email === profile?.account?.email)
      newUsers.unshift(originalUser);

      setUsers(newUsers);
    }

    setLoading(false);
  }

  const handleGetProfile = async () => {
    const { data, error } = await getProfile(true);

    if (data && !error) {
      setProfile(data);
    }
  }

  const handleSetPermission = async (id: string, permission: string) => {
    setLoading(true);

    const { error } = await updateAccountUser({ 
      userId: id,
      body: { 
        role: permission.toUpperCase().replace(' ', '_')
      } 
    });

    if (!error) {
      enqueueSnackbar(
        'Permission has been changed successfully',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Failed to change user permission',
        { variant: 'error' }
      )
    }

    setLoading(false);
    handleGetUsers();
  }

  const handleDeleteUser = async () => {
    setLoading(true);
    setUserToDelete(null);

    const { error } = await deleteAccountUser(userToDelete.uuid);

    if (!error) {
      enqueueSnackbar(
        `User has been deleted successfully`,
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar(
        `Failed to delete user`,
        { variant: 'error' }
      );
    }

    setLoading(false);
    handleGetUsers();
  }

  const handleDeactivateUser = async (id: string) => {
    setLoading(true);
    
    const { error } = await deactivateAccountUser(id);

    if (!error) {
      enqueueSnackbar(
        `User has been deactivated successfully`,
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar(
        `Failed to deactivate user`,
        { variant: 'error' }
      );
    }

    setLoading(false);
    handleGetUsers();
  }

  const handleActivateUser = async (id: string) => {
    setLoading(true);
    
    const { error } = await activateAccountUser(id);

    if (!error) {
      enqueueSnackbar(
        `User has been activated successfully`,
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar(
        `Failed to activate user`,
        { variant: 'error' }
      );
    }

    setLoading(false);
    handleGetUsers();
  }

  const handleInviteUsers = async (values) => {
    setShowInviteUserModal(false);
    setLoading(true);

    const { error } = await inviteAccountUser({
      email: values.email,
      fullName: values.name,
      role: values.permission.toUpperCase(),
    });

    if (!error) {
      enqueueSnackbar(
        `Invitation has been sent successfully`,
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar(
        `Failed to send invitation`,
        { variant: 'error' }
      );
    }

    setLoading(false);
    handleGetUsers();
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 99999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container
        style={{
          minWidth: '1017px',
          maxWidth: '100%',
          width: '100%',
          padding: '80px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '20px',
        }}
      >
        <Typography
          style={{
            fontSize: '48px',
            fontWeight: '600',
            color: '#000000',
            lineHeight: '58px'
          }}
        >
          User Management
        </Typography>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '20px'
          }}
        >
          <Box style={{ width: '271px' }}>
            {/* <TextField
              type={'search'}
              placeholder={'Search users'}
              value={searchTerm}
              onChange={searchUser}
              onSearchPress={refetch}
              onKeyPress={refetch}
            /> */}
          </Box>
          <Button
            variant="contained"
            startIcon={<AddUserIcon />}
            onClick={() => setShowInviteUserModal(true)}
            style={{
              minWidth: '130px',
              minHeight: '44px',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '19px',
              color: '#FFFFFF',
              padding: '12px 14px',
              borderRadius: '8px',
              textTransform: 'none',
              boxShadow: 'none',
              background: '#000000',
            }}
          >
            Add user
          </Button>
        </Box>
        <TableContainer
          component={Paper}
          style={{
            width: '100%',
            minWidth: '860px',
            boxShadow: 'none',
            margin: '0px auto'
          }}
        >
          <Table>
            <TableBody>
              {users?.map((row, index) => (
                <TableRow key={row.uuid}>
                  <TableCell 
                    align="center"
                    style={{
                      width: '5%'
                    }}
                  >
                    <Avatar
                      src={row.profilePhoto}
                      alt={row.name}
                      style={{ width: '40px', height: '40px', margin: '0 auto' }}
                    />
                  </TableCell>
                  <TableCell 
                    align="left"
                    style={{
                      width: '35%'
                    }}
                  >
                    <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', marginBottom: '2px' }}>
                      {row.name}
                    </Typography>
                    <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
                      {row.email}
                    </Typography>
                  </TableCell>
                  {/* <TableCell align="left" >
                    <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
                      {row.email}
                    </Typography>
                  </TableCell> */}
                  {/* <TableCell 
                    align="left"
                    style={{
                      width: '20%'
                    }}
                  >
                    <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '12px', color: '#475569' }}>
                      {row.laftActivity}
                    </Typography>
                  </TableCell> */}
                  <TableCell 
                    align="left"
                    style={{
                      width: '15%'
                    }}
                  >
                    <Dropdown
                      required
                      disabled={row.email === profile?.account?.email}
                      value={PERMISSIONS[row.role]}
                      options={Object.values(PERMISSIONS).map(item => ({ value: item, label: item }))}
                      onChange={(event, value) => handleSetPermission(row.uuid, event.target.value)}
                    />
                  </TableCell>
                  <TableCell 
                    align="right"
                    style={{
                      width: '35%'
                    }}
                  >
                    {/* <Button
                      color='secondary'
                      variant='outlined'
                      onClick={() => history.push(`/user/${row.id}`)}
                    >
                      View
                    </Button>
                    &nbsp;
                    &nbsp;
                    &nbsp; */}
                    <Button
                      color='secondary'
                      variant='outlined'
                      disabled={!row.isRegistered || row.email === profile?.account?.email}
                      onClick={() => {
                        row.isActive
                          ? handleDeactivateUser(row.uuid)
                          : handleActivateUser(row.uuid)
                      }}
                    >
                      {row.isActive
                        ? 'Deactivate'
                        : 'Activate'
                      }
                    </Button>
                    &nbsp;
                    &nbsp;
                    <IconButton
                      disabled={row.isRegistered || row.email === profile?.account?.email}
                      onClick={() => setUserToDelete(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!users?.length && (
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '80px 0px' }}>
              <Typography>
                No users
              </Typography>
            </Box>
          )}
          {/* {paginationConfig.count > 1 && (
            <Box
              style={{
                width: '100%',
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Pagination
                count={paginationConfig.count}
                page={paginationConfig.page}
                hideNextButton={!paginationConfig.count}
                hidePrevButton={!paginationConfig.count}
                onChange={(event: React.ChangeEvent, page: number) => setPaginationConfig({ count: paginationConfig.count, page })}
              />
            </Box>
          )} */}
        </TableContainer>
      </Container>

      <ConfirmationModal
        open={!!userToDelete}
        title={'Are you sure you want to delete user from the plarform?'}
        description={'Note that all data he or she saved and generated will be deleted'}
        rejectBtnText={'Close'}
        confirmBtnText={'Delete user'}
        onClose={() => setUserToDelete(null)}
        onReject={() => setUserToDelete(null)}
        onConfirm={() => handleDeleteUser()}
      />

      <InviteUserModal
        open={showInviteUserModal}
        onClose={() => setShowInviteUserModal(false)}
        onInvite={handleInviteUsers}
      />
    </>
  );
};

export default UserManagement;
