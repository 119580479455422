import React from "react";
import { useStyles } from './styles';
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Paper, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ReactComponent as Robot_1 } from "../../../../assets/robots/robot_1.svg";

const CopilotRef = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Paper
        className={classes.pebble}
        onClick={() => history.push('/copilot/general')}
      >
        <ArrowForwardIcon
          fontSize="small"
          className={classes.arrowIcon}
        />
        <Box className={classes.pebbleContent}>
          <Typography className={classes.pebbleTitle}>
            Pebbles Copilot
          </Typography>

          <Typography className={classes.pebbleDescription}>
            Your very own go to market companion.
          </Typography>
        </Box>
        <Robot_1 className={classes.pebbleImage} />
        {/* <img className={classes.pebbleImage} src={Robot_1} /> */}
      </Paper>
    </>
  );
};

export default CopilotRef;
