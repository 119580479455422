import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  // container: {
  //   padding: 20,
  //   borderRadius: 28,
  //   backgroundColor: "#F1F5F9"
  // },
  container: {
    padding: "20px 25px",
    borderRadius: 28,
    backgroundColor: "#F1F5F9",
  },

  list: {
    borderRadius: 28,
    padding: 20,
    background: "linear-gradient(128.85deg, #F9F9F9 -14.81%, #EBE2CF 87.66%)"
  },

  title: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "21.6px",
    color: "#475569"
  },

  content: {
    width: "100%",
    marginTop: 20
  },

  button: {
    padding: "10px",
    textTransform: "none",
    marginTop: 16,
    backgroundColor: "#fff",
    borderRadius: "8px"
  },

  arrowIcon: {
    transform: "rotate(-45deg)"
  },

  flow: {
    display: "flex",
    borderBottom: "1px solid #94A3B8",
    paddingBottom: 16,
    justifyContent: "space-between",
    alignItems: "baseline",
    gap: '15px',

    "& + &": {
      marginTop: 16
    },

    "&:last-child": {
      borderBottom: "none",
    },
  },

  flowInner: {},

  flowHead: {
    display: "flex",
    alignItems: "center",

    "@media (max-width: 767.98px)": {
      alignItems: "flex-start"
    }
  },

  flowIcon: {
    width: 20,
    height: "auto"
  },

  flowTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "21px",
    marginLeft: 8,
    flex: 1
  },

  flowDescription: {
    paddingLeft: 28,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "14.4px",
    color: "#475569",
    marginTop: 2
  },

  flowButton: {
    marginLeft: 20,
    backgroundColor: "#fff",
    borderRadius: "8px",
    textTransform: "capitalize"
  },
  icon: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  noData: {
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal'
  }
}));
