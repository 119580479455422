import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import TextField from "../../../../components/main/CustomFields/TextField";
import { generateReply, getReplyTemplate } from "../../../../redux/services/tactical-outreach";
import * as Yup from 'yup';
import RenderUrlInput from "../../../TacticalOutreachBuilder/components/SubSectionElement/components/RenderUrlInput";
import RenderRadioButtons from "../../../TacticalOutreachBuilder/components/SubSectionElement/components/RenderRadioButtons";
import TooltipModal from "../../../TacticalOutreachBuilder/components/TooltipModal";
import { Form, Formik } from "formik";
import { generateInitialValue, generateParameters, generateYupSchema } from "../../../TacticalOutreachBuilder/helpers";
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy_email_content.svg';
import { ReactComponent as GreenCheckmark } from '../../../../assets/icons/green_checkmark_mini.svg';
import _ from 'lodash';
import { enqueueSnackbar } from "notistack";
import { observableService } from "../../../../services/observable";
import SubSectionElement from "../../../TacticalOutreachBuilder/components/SubSectionElement";
import { Editor } from 'react-draft-wysiwyg';

import { useStyles } from "./styles";
import Markdown from "react-markdown";
import { convertHTMLToState, convertMarkdownToHtml, convertStateToHTML, copyHtmlToClipboard } from "../../helpers";

interface Props {
  email: any;
  containerRef: any;
}

const ReplyGenerator: React.FC<Props> = ({
  email,
  containerRef
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>(null);
  const [reply, setReply] = useState<string>('');
  const [tailoredReply, setTailoredReply] = useState<string | null>(null);
  const [validationSchema, setValidationSchema] = useState<any>(null);
  const [tooltipMessage, setTooltipMessage] = useState<string | null>(null);
  const [editorState, setEditorState] = useState<any>(null);

  const classes = useStyles();

  useEffect(() => {
    setTailoredReply(null);
    setReply('');
    setTemplate(null);
    setValidationSchema(null);

    scrollTo('top');
  }, [email]);

  const handleGetReplyTemplate = async () => {
    setLoading(true);

    const { data, error } = await getReplyTemplate();

    scrollTo('bottom');

    if (data && !error) {
      setTimeout(() => {
        const shape = generateYupSchema(data, null);
  
        setValidationSchema(shape);
        setTemplate(data);
        setLoading(false);
  
        scrollTo('bottom');
      }, 2000);
    } else {
      setTemplate(null);
    }
  };

  const handleGenerateReply = async (values) => {
    setTailoredReply('');
    setLoading(true);

    const parameters = generateParameters(
      { 
        ...template, 
        id: email.campaignId 
      }, 
      values
    );

    const { data, error } = await generateReply({
      ...parameters,
      emailId: email.id,
      arguments: {
        campaignTypeId: parameters.campaignTypeId,
        sectionAnswerList: parameters.sectionAnswerList
      }
    });

    if (data && !error) {
      const content = convertMarkdownToHtml(data.content);

      setEditorState(
        convertHTMLToState(content)
      );
      setTailoredReply(data.content);

      observableService.sendEvent('Increase usage amount');

      scrollTo('bottom');
    } else {
      enqueueSnackbar(
        'Failed to generate reply',
        { variant: 'error' }
      )
    }

    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const scrollTo = (directionY) => {
    if (containerRef) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: directionY === 'bottom' ? containerRef.current.scrollHeight - 1 : 1,
          behavior: 'smooth', // Smooth scrolling
        });
      }, 100);
    }
  };

  const getInitialValues = () => {
    const initialValues = {};

    Object.entries(Yup.object().shape(validationSchema).describe().fields).forEach(([key, value]) => {
      initialValues[key] = generateInitialValue({ ...value, nullable: !!validationSchema[key]?._nullable })
    });

    return initialValues;
  }

  const handleClearReply = () => {
    setTailoredReply(null);
    setReply('');
    setTemplate(null);
    setValidationSchema(null);
  }

  const handleCopyReply = async () => {
    const html = convertStateToHTML(editorState);

    const isSuccessully = await copyHtmlToClipboard(html);

    isSuccessully
      ? enqueueSnackbar(
          'Copied to clipboard',
          { variant: 'info' }
        )
      : enqueueSnackbar(
          'Failed to copy',
          { variant: 'error' }
        )
  }

  return (
    <>
      <Box
        style={{
          width: '100%',
          maxWidth: '1080px',
          minWidth: '650px',
          padding: '0px 20px 60px 20px',
          margin: '0 auto',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            background: '#F8FAFC',
            borderRadius: '8px',
            padding: '12px'
          }}
        >
          {tailoredReply
            ? <Box 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}
              >
                <Typography style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                  <GreenCheckmark /> Your reply is ready!
                </Typography>
                <Typography className={classes.message}>
                  <Editor 
                    editorState={editorState}
                    readOnly={true}
                    toolbarHidden={true}
                    wrapperClassName={`wrapper-class ${classes.editorWrapper}`}
                    toolbarClassName={`toolbar-class ${classes.editorToolbar}`}
                    editorClassName={`editor-class ${classes.editorTextarea}`}
                  />
                  {/* <Markdown
                    className={classes.tailoredReply}
                  >
                    {tailoredReply}
                  </Markdown> */}
                </Typography>
                <Box 
                  style={{
                    display: 'flex',
                    gap: '8px',
                    marginTop: '12px'
                  }}
                >
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    startIcon={<CopyIcon />}
                    onClick={() => handleCopyReply()}
                  >
                    Copy
                  </Button>
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={() => handleClearReply()}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            : <>
                {typeof tailoredReply !== 'string' && (
                  <TextField
                    value={reply}
                    type={'textarea'}
                    label={'Incoming reply'}
                    placeholder={'Paste the response from your lead to generate a tailored reply...'}
                    disabled={template && validationSchema && loading}
                    onChange={(event) => {
                      if (!reply.length && event.target.value.length) {
                        handleGetReplyTemplate();
                      } else if (!event.target.value.length) {
                        setTemplate(null);
                      }

                      setReply(event.target.value || '');
                    }}
                  />
                )}

                {loading
                  ? <CircularProgress
                      color="inherit"
                      style={{
                        width: '25px',
                        height: '25px',
                        margin: '40px auto'
                      }}
                    />
                  : template && validationSchema && (
                      <Formik
                        // innerRef={formRef}
                        initialValues={getInitialValues()}
                        validationSchema={Yup.object().shape(validationSchema)}
                        validateOnChange={true}
                        validateOnBlur={true}
                        isInitialValid={false}
                        onSubmit={(values: any) => handleGenerateReply(values)}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          submitForm,
                          validateField,
                          validateForm,
                          resetForm,
                          setValues,
                          setErrors,
                          setFieldValue,
                          setFieldError,
                          setFieldTouched
                        }) => (
                          <Form
                            translate={undefined}
                            onKeyDown={handleKeyDown}
                          >
                            <Box
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px'
                              }}
                            >
                              {values && errors && touched && (
                                template.sections[0].subSections.map((subSection, index) => (
                                  <Box key={index}>
                                    <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', marginBottom: '8px' }}>
                                      {subSection.title}
                                      &nbsp;
                                      <Typography style={{ display: 'inline', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: 'red' }}>
                                        *
                                      </Typography>
                                    </Typography>
                                    {subSection.elements.map(elem => (
                                      <SubSectionElement
                                        item={elem}
                                        name={`1-${subSection.id}-${elem.id}`}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        validationSchema={validationSchema}
                                        template={template}
                                        uukey={null}
                                        setValues={setValues}
                                        setErrors={setErrors}
                                        setFieldValue={(field: string, value: any, shouldValidate?: boolean) => {
                                          setFieldError(field, undefined);
                                          setFieldValue(field, value, !!shouldValidate);
                                        }}
                                        setFieldError={setFieldError}
                                        setFieldTouched={setFieldTouched}
                                        setValidationSchema={setValidationSchema}
                                        setTooltipMessage={setTooltipMessage}
                                      />
                                    ))}
                                  </Box>
                                ))
                              )}
                              <Button
                                type={'submit'}
                                color={'primary'}
                                variant={'contained'}
                              >
                                Generate reply
                              </Button>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    )
                }
              </>
          }
        </Box>
      </Box>

      <TooltipModal
        open={!!tooltipMessage?.length}
        message={tooltipMessage}
        onClose={() => setTooltipMessage(null)}
      />
    </>
  );
};

export default ReplyGenerator;
