import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // display:'flex',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
    background: '#EBE2CF'
  },
}));