import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Typography } from '@material-ui/core';
import { ReactComponent as AccordionExpandIcon } from '../../../../assets/icons/arrow_down_filled.svg';
import { ReactComponent as InfoHintIcon } from '../../../../assets/icons/info_hint.svg';
import { ReactComponent as GreenCheckmarkIcon } from '../../../../assets/icons/green_checkmark_mini.svg';
import SubSectionElement from '../SubSectionElement';
import { numberToWords } from '../../../../helpers/text';
import { findElementByName, findReferencedSubSection, generateInitialValue, generateValidationRules, handleHideSubSections, handleMaxElements, isFieldNotEmpty } from '../../helpers';

import { useStyles } from './styles';

interface Props {
  section: any;
  values: any;
  errors: any;
  touched: any;
  validationSchema: any;
  template: any;
  validateForm: () => void;
  setValues: (values: any, shouldValidate?: boolean) => void;
  setErrors: (errors: any) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setValidationSchema: (shape: any) => void;
  setTooltipMessage: (message: string) => void;
  getCompletedFields: (startName: string, values: any, item?: string) => { all: any, required: any };
}

const SectionAccordion: React.FC<Props> = (props) => {
  const {
    id,
    name,
    subSections,
    attributes,
  } = props.section;

  const [completedFields, setCompletedFields] = useState<any>({
    all: {
      fields: {},
      amount: 0,
    },
    required: {
      fields: {},
      amount: 0,
    },
  });

  const classes = useStyles();

  const alternativeSubSections = attributes.filter(item => item.type === 'SEPARATED_QUESTION_ATTRIBUTE').map(item => ({
    ...item.question,
    key: item.key,
    title: item.key
  }));

  const defaultRequiredOptionsNumber = alternativeSubSections.length 
    ? alternativeSubSections.length
    : attributes.find(item => item.type === 'SECTION_CONDITION_ATTRIBUTE').requiredOptionsNumber
  const defaultNecessaryOptionsNumber = attributes.find(item => item.type === 'SECTION_CONDITION_ATTRIBUTE').necessaryOptionsNumber;
  
  const requiredOptionsNumber = alternativeSubSections.length 
    ? alternativeSubSections.length 
    : Object.keys(completedFields.required.fields).length;

  const necessaryOptionsNumber = defaultNecessaryOptionsNumber - (defaultRequiredOptionsNumber - requiredOptionsNumber);

  useEffect(() => {
    if (alternativeSubSections.length) {
      let newCompletedFields = {...completedFields};

      alternativeSubSections.forEach(item => {
        const { all, required } = props.getCompletedFields(id, props.values, item.key);

        newCompletedFields = {
          all: {
            fields: {
              ...newCompletedFields.all.fields,
              [item.key]: all.fields
            },
            amount: {
              ...newCompletedFields.all.amount,
              [item.key]: all.amount
            }
          },
          required: {
            fields: {
              ...newCompletedFields.required.fields,
              [item.key]: required.fields
            },
            amount: {
              ...newCompletedFields.required.amount,
              [item.key]: required.amount
            }
          },
        }
      })
    } else {
      const { all, required } = props.getCompletedFields(`${id}`, props.values);
  
      setCompletedFields({
        all,
        required,
      });
    }
    
  }, [props.section, props.values, props.validationSchema]);

  const getElementRules = (subSection) => {
    const types = [
      'MAX_LENGTH_ATTRIBUTE',
      'MIN_LENGTH_ATTRIBUTE',
      'MAX_VALUE_ATTRIBUTE',
      'MIN_VALUE_ATTRIBUTE',
      'MAX_OPTIONS_ATTRIBUTE',
      'MAX_ELEMENTS_ATTRIBUTE'
    ]
    let attributes = [];

    if (subSection.attributes && subSection.attributes.length > 0) {
      attributes.push(
        ...subSection.attributes.filter(item => types.includes(item.type))
      );
    }
    if (subSection.elements && subSection.elements.length > 0) {
      subSection.elements.forEach(element => {
        if (element.attributes && element.attributes.length > 0) {
          attributes.push(
            ...element.attributes.filter(item => types.includes(item.type))
          );
        }
      });
    }

    // filter by unique type
    attributes = attributes.filter((item, index, self) => self.findIndex(t => t.type === item.type && t.option === item.option) === index);

    // filter by referenced element
    attributes = attributes.filter(item => item.option ? item.option === props.values[Object.values(item.elementReference).join('-')] : item);

    return (
      attributes.length && attributes.every(item => item.type.toLowerCase().includes('on_condition'))
        ? <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
            Recommended {attributes.find(item => item.type.toLowerCase().includes('min'))?.value}-{attributes.find(item => item.type.toLowerCase().includes('max'))?.value} symbols
          </Typography>
        : attributes.map((item, index) => (
            <Typography
              key={index}
              style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}
            >
              &bull; &nbsp;
              {item.type.toLowerCase().includes('max')
                ? 'Max '
                : 'Min '
              }
              {item.value + ' '}
              {item.type.toLowerCase().includes('length')
                ? item.value > 1 ? 'characters' : 'character'
                : item.type.toLowerCase().includes('elements')
                  ? item.value > 1 ? 'fields' : 'field'
                  : item.type.toLowerCase().includes('value')
                    ? ''
                    : item.value > 1 ? 'options' : 'option'
              }
            </Typography>
          ))
    )
  }

  const getCombinationIsReached = () => {
    if (alternativeSubSections.length) {
      return {
        disableFields: [],
        reached: false
      };
    } else {
      if (necessaryOptionsNumber !== requiredOptionsNumber) {
        const combinationCounter = necessaryOptionsNumber;
        const completedCounter = Object.keys(Object.fromEntries(Object.entries(completedFields.all.fields).filter(([key, value]) => value))).length;
        const disableFields = Object.keys(Object.fromEntries(Object.entries(completedFields.all.fields).filter(([key, value]) => !value && !Object.keys(completedFields.all.fields).some(item => item.startsWith(key) && item !== key))));

        return {
          disableFields,
          reached: completedCounter >= combinationCounter
        };
      } else {
        return {
          disableFields: [],
          reached: false
        };
      }
    }
  }

  const getIsFieldDisabled = (subSection) => {
    if (subSection.key) {
      return false
    } else {
      if (requiredOptionsNumber !== necessaryOptionsNumber) {
        const name = `${id}-${subSection.id}`;
        const disabled1 = getCombinationIsReached().reached && getCombinationIsReached().disableFields.some(item => item.startsWith(name) && item !== name);
        const disabled2 = getIsOptionalField(subSection) && completedFields.required.amount < requiredOptionsNumber;
  
        return disabled1 || disabled2;
      } else {
        return false
      }
    }
  }

  const getIsOptionalField = (subSection) => {
    const key = `${props.section.id}-${subSection.id}`;

    return !(
      subSection.elements.some(item => 
        subSection.key
          ? props.validationSchema[subSection.key]?.fields[`${key}-${item.id}`]?._exclusive?.required
          : props.validationSchema[`${key}-${item.id}`]?._exclusive?.required
      )
    )
  }

  const getIsElementDisabled = (subSection, targetName, parentName) => {
    const maxElementsAttribute = subSection.attributes.find(item => item.type === 'MAX_ELEMENTS_ATTRIBUTE');

    if (maxElementsAttribute?.value === 1) {
      const restElementName = Object.keys(props.values).find(item => item.startsWith(parentName) && item !== targetName);

      if (isFieldNotEmpty(props.values[restElementName])) {
        return true
      }
    }

    return false
  }

  const getIsFieldHidden = (subSection) => {
    const hideUnderConditionAttributes = subSection.attributes.filter(item => item.type === "HIDE_UNDER_CONDITION_ATTRIBUTE");
    const targetAttribute = hideUnderConditionAttributes.find(item => item.option === props.values[Object.values(item.elementReference).join('-')]);

    return !!targetAttribute;
  }

  const handleSetValue = (field: string, value: any, shouldValidate: boolean, item: any, subSection: any) => {
    props.setFieldError(field, undefined);
    props.setFieldValue(field, value, !!shouldValidate);

    setTimeout(() => {
      // handleMaxElements(
      //   props.section,
      //   subSection,
      //   item,
      //   props.values,
      //   value,
      //   props.validationSchema,
      //   props.setFieldError,
      //   props.setValidationSchema,
      //   props.validateForm
      // );

      handleHideSubSections(
        field,
        props.template,
        subSection,
        props.values,
        value,
        props.validationSchema,
        props.setFieldValue,
        props.setFieldError,
        props.setFieldTouched,
        props.setValidationSchema,
      );
    }, 500); // setTimeout to avoid re-setting of values, errors, touches and validation schema dataset
  }

  const countAmountOfCompletedFields = (fields) => {
    let trueCount = 0;

    for (const category in fields) {
        if (Object.hasOwn(fields, category)) {
            for (const key in fields[category]) {
                if (fields[category][key] === true) {
                    trueCount++;
                }
            }
        }
    }

    return trueCount;
  }

  return (
    <Accordion
      defaultExpanded
      classes={{
        root: classes.accordionRoot
      }}
    >
      <AccordionSummary
        expandIcon={<AccordionExpandIcon style={{ color: 'white' }} />}
        aria-controls={`section${id}-content`}
        id={`section${id}-header`}
        classes={{
          root: `${classes.accordionSummary} ${(alternativeSubSections.length ? countAmountOfCompletedFields(completedFields.required.fields) : completedFields.required.amount) >= requiredOptionsNumber ? classes.accordionSummaryFilled : ''}`,
          expanded: classes.accordionSummary
        }}
        style={{
          background: (alternativeSubSections.length ? countAmountOfCompletedFields(completedFields.required.fields) : completedFields.required.amount) >= requiredOptionsNumber
            ? '#D1FAE5'
            : '#000000',
          color: (alternativeSubSections.length ? countAmountOfCompletedFields(completedFields.required.fields) : completedFields.required.amount) >= requiredOptionsNumber
            ? 'black'
            : 'white'
        }}
      >
        <Typography
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '17px',
            textTransform: 'uppercase',
            color: (alternativeSubSections.length ? countAmountOfCompletedFields(completedFields.required.fields) : completedFields.required.amount) >= requiredOptionsNumber
              ? 'black'
              : 'white'
          }}
        >
          {(alternativeSubSections.length ? countAmountOfCompletedFields(completedFields.required.fields) : completedFields.required.amount) >= requiredOptionsNumber && (
            <GreenCheckmarkIcon />
          )}
          {name}
        </Typography>
        <Typography
          style={{
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '14px',
            textTransform: 'lowercase',
            marginRight: '8px',
            color: (alternativeSubSections.length ? countAmountOfCompletedFields(completedFields.required.fields) : completedFields.all.amount) >= requiredOptionsNumber
              ? 'black'
              : 'white'
          }}
        >
          {alternativeSubSections.length
            ? `${countAmountOfCompletedFields(completedFields.required.fields)}/${defaultRequiredOptionsNumber} completed`
            : completedFields.required.amount >= requiredOptionsNumber
              ? `${requiredOptionsNumber}/${requiredOptionsNumber} completed`
              : `${completedFields.required.amount}/${requiredOptionsNumber} completed`
          }
        </Typography>
      </AccordionSummary>
      {!!subSections.length && requiredOptionsNumber !== necessaryOptionsNumber && (
        <AccordionDetails
          classes={{
            root: classes.accordionDetails
          }}
          style={{
            background: '#F3F3F3',
            padding: '16px 40px'
          }}
        >
          <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '17px', display: 'flex', alignItems: 'center', gap: '5px' }}>
            {necessaryOptionsNumber === 1
              ? 'Select'
              : 'Choose a combination of'
            }
            <Typography style={{ fontWeight: '700', color: '#D97706' }}>
              {necessaryOptionsNumber} option{necessaryOptionsNumber === 1 ? '' : 's'}.
            </Typography>
            The first {numberToWords(requiredOptionsNumber)} {requiredOptionsNumber > 1 ? 'are' : 'is'} mandatory.
          </Typography>
        </AccordionDetails>
      )}
      {[...subSections, ...alternativeSubSections].filter(subSection => !getIsFieldHidden(subSection)).map((subSection, index) => (
        <AccordionDetails
          key={index}
          classes={{
            root: index === 0
              ? !subSections.length || requiredOptionsNumber === necessaryOptionsNumber
                ? classes.accordionDetails
                : classes.accordionDetails2
              : classes.accordionDetails2
          }}
          style={{
            background: getIsOptionalField(subSection)
              ? 'linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)'
              : '#FFFFFF',
            pointerEvents: getIsFieldDisabled(subSection)
              ? 'none'
              : 'all',
            opacity: getIsFieldDisabled(subSection)
              ? '0.5'
              : '1',
            // display: getIsFieldHidden(subSection)
            //   ? 'none'
            //   : 'flex'
          }}
        >
          <Box className={classes.subSectionContent}>
            <Box className={classes.subSectionTitleWrapper}>
              <Box className={classes.subSectionTitle}>
                <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {Object.entries(subSection.key ? completedFields.all.fields[subSection.key] || {} : completedFields.all.fields).some(([key, value]) => key.startsWith(`${id}-${subSection.id}`) && value === true && (props.touched[key] ? !props.errors[key] : true)) && (
                    <GreenCheckmarkIcon />
                  )}
                  <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                    {!subSection.key && (
                      `${index + 1}.`
                    )}
                    &nbsp;{subSection.title} &nbsp;
                    {getIsOptionalField(subSection)
                      ? <Typography style={{ display: 'inline', fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
                          (optional)
                        </Typography>
                      : <Typography style={{ display: 'inline', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: 'red' }}>
                          *
                        </Typography>
                    }
                  </Typography>
                </Box>
                {subSection.attributes.some(item => item.type === 'TOOLTIP_ATTRIBUTE') && (
                  <IconButton
                    onClick={() => {
                      props.setTooltipMessage(
                        subSection.attributes.find(item => item.type === 'TOOLTIP_ATTRIBUTE')?.value
                      )
                    }}
                  >
                    <InfoHintIcon />
                  </IconButton>
                )}
              </Box>
              <Box className={classes.subSectionRules}>
                {getElementRules(subSection)}
              </Box>
            </Box>
            {subSection.elements.map((item, index) => (
              <Box
                key={index}
                id={`${id}-${subSection.id}-${item.id}`}
                style={
                  getIsElementDisabled(subSection, `${id}-${subSection.id}-${item.id}`, `${id}-${subSection.id}`)
                    ? {
                        opacity: '0.5',
                        pointerEvents: 'none'
                      }
                    : {}
                }
              >
                <SubSectionElement
                  item={item}
                  name={`${id}-${subSection.id}-${item.id}`}
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  validationSchema={props.validationSchema}
                  template={props.template}
                  uukey={subSection.key}
                  setValues={props.setValues}
                  setErrors={props.setErrors}
                  setFieldValue={(field: string, value: any, shouldValidate?: boolean) => handleSetValue(field, value, !!shouldValidate, item, subSection)}
                  setFieldError={props.setFieldError}
                  setFieldTouched={props.setFieldTouched}
                  setValidationSchema={props.setValidationSchema}
                  setTooltipMessage={props.setTooltipMessage}
                />
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      ))}
    </Accordion>
  );
};

export default SectionAccordion;
