import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  group: {
    display: "flex",
    height: "100%",
  },

  item: {},

  container: {
    padding: 20,
    borderRadius: 28,
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    position: "relative",
    minHeight: 160,
    height: "100%",
    cursor: 'pointer'
  },

  image: {
    position: "absolute",
    top: 0,
    right: 20,
    width: "auto",
    height: 82,
  },

  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "19.2px",
  },

  content: {
    marginTop: 45,
  },

  description: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "14.4px",
    color: "#475569",
    marginTop: 8,
  },
}));
